<template>
    <div>
        <div>
            <el-container>
                <el-header height="80px">
                    <!--        上右下左-->
                    <div class="titleClass">
                        <div>
                            <div class="titleClass2">
                                <div>
                                    <img src="../assets/img/logo.png" width="300px" height="80px">
                                </div>
                                <div style="font-size: 27px;font-weight: bold;font-family: '楷体'">
                                    BIWIN后台管理系统
                                </div>
                            </div>
                        </div>

                        <div style="display: flex;justify-content: space-between;">
                            <div style="padding: 17px">
                                <div id="he-plugin-simple"></div>
                            </div>
                            <div>
                                <div>
                                    <i class="el-icon-user-solid"></i>
                                    <el-dropdown trigger="click">
                                        <span v-html="realName" class="spanBox"></span>
                                        <i class="el-icon-caret-bottom"></i>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item><span @click="dialogVisible=true">修改密码</span>
                                            </el-dropdown-item>
                                            <el-dropdown-item><span @click="logout">退出登录</span></el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                        </div>


                    </div>

                </el-header>
                <el-container>
                    <el-aside class="asideClass" style="width: 170px">

                        <el-menu class="menuStyle" v-if="roleFlag==1">
                            <router-link to="/accountList">
                                <el-menu-item index="2">
                                    <i class="iconfont icon-zhanghu1"></i>
                                    <el-button class="fontStyle" type="text">代理账号</el-button>
                                </el-menu-item>
                            </router-link>
                        </el-menu>

                        <!--        :default-openeds="['1-1']"-->
                        <el-menu class="menuStyle" v-if="roleFlag==1">
                            <router-link to="/projectTypeList">
                                <el-menu-item index="3">
                                    <i class="iconfont icon-fenlei"></i>
                                    <el-button class="fontStyle" type="text">项目分类</el-button>
                                </el-menu-item>
                            </router-link>
                        </el-menu>

                        <!--        :default-openeds="['1-1']"-->
                        <el-menu class="menuStyle" v-if="roleFlag==1">
                            <router-link to="/projectList">
                                <el-menu-item index="4">
                                    <i class="iconfont icon-xiangmu_xiangmuguanli"></i>
                                    <el-button class="fontStyle" type="text">项目管理</el-button>
                                </el-menu-item>
                            </router-link>
                        </el-menu>
                        <!--<button v-if="arr.indexOf('user:delete') > -1">删除按钮</button>-->

                        <el-menu class="menuStyle" v-if="roleFlag==1">
                            <router-link to="/paySet">
                                <el-menu-item index="5">
                                    <i class="iconfont icon-zhifu"></i>
                                    <el-button class="fontStyle" type="text">支付设置</el-button>
                                </el-menu-item>
                            </router-link>
                        </el-menu>

                        <el-menu class="menuStyle">
                            <router-link to="/userList">
                                <el-menu-item index="6">
                                    <i class="iconfont icon-huiyuanka"></i>
                                    <el-button class="fontStyle" type="text">会员中心</el-button>
                                </el-menu-item>
                            </router-link>
                        </el-menu>

                        <el-menu class="menuStyle" v-if="roleFlag==1">
                            <router-link to="/upAudit">
                                <el-menu-item index="7">
                                    <i class="iconfont icon-chongzhi"></i>
                                    <el-button class="fontStyle" type="text">充值审核</el-button>
                                </el-menu-item>
                            </router-link>
                        </el-menu>

                        <el-menu class="menuStyle" v-if="roleFlag==1">
                            <router-link to="/withdrawal">
                                <el-menu-item index="8">
                                    <i class="iconfont icon-jiangli"></i>
                                    <el-button class="fontStyle" type="text">提现审核</el-button>
                                </el-menu-item>
                            </router-link>
                        </el-menu>

                        <el-menu class="menuStyle" v-if="roleFlag==1">
                            <router-link to="/shahe">
                                <el-menu-item index="9">
                                    <i class="iconfont icon-duihuanma"></i>
                                    <el-button class="fontStyle" type="text">沙盒兑换</el-button>
                                </el-menu-item>
                            </router-link>
                        </el-menu>


                        <el-menu class="menuStyle" v-if="roleFlag==1">
                            <router-link to="/rewardSet">
                                <el-menu-item index="10">
                                    <i class="iconfont icon-xitongshezhi"></i>
                                    <el-button class="fontStyle" type="text">系统设置</el-button>
                                </el-menu-item>
                            </router-link>
                        </el-menu>

                        <el-menu class="menuStyle" v-if="roleFlag==1">
                            <router-link to="/data">
                                <el-menu-item index="11">
                                    <i class="iconfont icon-shuzhuangtu"></i>
                                    <el-button class="fontStyle" type="text">数据统计</el-button>
                                </el-menu-item>
                            </router-link>
                        </el-menu>


                    </el-aside>

                    <el-main>
                        <!--主体内容-->
                        <router-view/>
                    </el-main>
                </el-container>
            </el-container>
        </div>

        <el-dialog
                title="修改密码"
                close-on-press-escape
                :before-close="closeBefore"
                :visible.sync="dialogVisible"
                width="30%"
        >
            <el-form :model="ruleForm" status-icon label-width="100px" class="demo-ruleForm">
                <el-form-item label="旧密码" prop="oldPass">
                    <el-input type="password" v-model="ruleForm.oldPassword" placeholder="请输入旧密码"
                              autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="pass">
                    <el-input type="password" v-model="ruleForm.newPassword" placeholder="请输入新密码"
                              autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="checkPass">
                    <el-input type="password" v-model="ruleForm.checkNewPass" placeholder="请再次输入新密码"
                              autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div class="buttonClass">
                <el-button @click="closeUpdate">取 消</el-button>
                <el-button type="primary" @click="updatePass">确 定</el-button>
            </div>
        </el-dialog>

        <!--音效-->
        <!--        <audio controls="controls" hidden src="../assets/mp3/您有新订单，请及时处理.mp3" muted ref="audio"></audio>-->

    </div>
</template>


<script>
    import global from "../utils/global";

    export default {
        name: "Main",
        data() {
            return {
                realName: "",
                dialogVisible: false,
                loginValue: null,
                ruleForm: {
                    userId: null,
                    checkNewPass: "",
                    oldPassword: "",
                    newPassword: ""
                },
                menuList: [],
                mp3Url: "",
                roleFlag: null,
                websocket: null,
                audio: new Audio()
            }
        },
        mounted() {
            //和风天气插件调用
            window.WIDGET = {
                CONFIG: {
                    "modules": "01234",
                    "background": "5",
                    "tmpColor": "FFFFFF",
                    "tmpSize": "16",
                    "cityColor": "FFFFFF",
                    "citySize": "16",
                    "aqiColor": "FFFFFF",
                    "aqiSize": "16",
                    "weatherIconSize": "24",
                    "alertIconSize": "18",
                    "padding": "10px 10px 10px 10px",
                    "shadow": "0",
                    "language": "auto",
                    "borderRadius": "5",
                    "fixed": "false",
                    "vertical": "top",
                    "horizontal": "left",
                    "key": "91ac73ca5baa4a5d880540ea7d92e89d"
                }
            };
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = "https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0";
            document.getElementsByTagName('head')[0].appendChild(script);

            let roleFlag = sessionStorage.getItem('roleFlag');
            this.roleFlag = roleFlag;
            if (roleFlag && roleFlag == 1 && this.ruleForm.userId) {
                //当前为管理员登陆
                //连接通信
                this.initWebSocket(this.ruleForm.userId);
            }

            window.addEventListener("click", this.clickOther);
        },
        sockets: {
            // 连接成功
            onopen() {
                console.log("连接websocket成功");
            },
            // 接收消息
            onmessage(e) {
                if (e.data === '有新的充值订单' || e.data === '有新的提现订单') {
                    //暂停
                    //this.audio.pause()
                    //this.audio.muted=true
                    //收到充值通知
                    //this.play();
                    // let audio = new Audio();
                    // audio.src="../assets/mp3/您有新订单，请及时处理.mp3";
                    // audio.play()
                    // 开启自动播放
                    this.audio.src = require("../assets/mp3/您有新订单，请及时处理.mp3");
                    this.audio.autoplay = true;
                    // 绑定播放完毕事件,每次放完了可以做一些收尾的事
                    // this.audio.addEventListener('ended', () => {
                    //     // dosomething...
                    //     console.log('播放结束了..')
                    //     //播放
                    //     this.audio.play()
                    // });
                    this.audio.loop = true
                }
            },
            // 连接报错
            onerror() {
                console.log("连接websocket出错");
            },
            // 关闭连接
            onclose() {
                console.log("websocket关闭");
            },
        },
        beforeDestroy() {
            // 销毁websocket
            this.$disconnect();
            // 实例销毁之前对点击事件进行解绑
            window.removeEventListener('click', this.clickOther);
        },
        created() {
            this.realName = sessionStorage.getItem("realName");
            this.ruleForm.userId = sessionStorage.getItem('userId');
            //关闭网页时发送请求
            //window.addEventListener( 'unload', e => this.set() );

        },

        methods: {
            //播放音效
            play() {
                this.$refs.audio.currentTime = 0;//从头开始播放
                this.$refs.audio.play(); //播放
                // setTimeout(()=>{
                //     this.$refs.audio.pause();//停止
                // },4000);
            },

            //点击页面事件
            clickOther() {
                //console.log('点击页面了')
                //暂停音效
                this.audio.pause();
            },

            closeUpdate() {
                this.dialogVisible = false;
                this.ruleForm = {};
            },
            closeBefore(done) {
                this.ruleForm = {};
                done();
            },
            updatePass() {
                if (this.ruleForm.oldPassword == "" || this.ruleForm.oldPassword == null) {
                    this.$message.warning("请输入旧密码!");
                    return;
                }
                if (this.ruleForm.oldPassword.length < 5 || this.ruleForm.oldPassword.length > 12) {
                    this.$message.warning("旧密码长度5-12位!");
                    return;
                }
                if (this.ruleForm.newPassword == "" || this.ruleForm.newPassword == null) {
                    this.$message.warning("请输入新密码!");
                    return;
                }
                if (this.ruleForm.newPassword.length < 5 || this.ruleForm.newPassword.length > 12) {
                    this.$message.warning("新密码长度5-12位!");
                    return;
                }
                if (this.ruleForm.newPassword != this.ruleForm.checkNewPass) {
                    this.$message.warning("两次输入密码不一致!");
                    return;
                }
                let obj = {
                    userId: this.ruleForm.userId,
                    oldPassword: this.ruleForm.oldPassword,
                    newPassword: this.ruleForm.newPassword
                }
                this.axios.post("/admin/updatePass", obj).then(resp => {
                    if (resp.data.code == 200) {
                        this.$message({
                            message: resp.data.message,
                            type: 'success'
                        });
                        //this.ruleForm = {};
                        //this.dialogVisible = false;
                        //清空，重新登录
                        sessionStorage.clear();
                        this.$router.replace("/login");
                    }

                })
            },
            goHomePage(url) {
                this.$router.replace(url);
            },
            logout() {
                this.$confirm('确定退出登录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get("/admin/logout").then(resp => {
                        if (resp.data.code == 200) {
                            this.$message({
                                type: 'success',
                                message: resp.data.message
                            });
                            //清空session
                            sessionStorage.clear();
                            //跳转到登录页
                            this.$router.replace("/login")
                        }

                    })
                })
            },

            // websocketonmessage(e) {
            //     if (e.data === '有新的充值订单' || e.data === '有新的提现订单') {
            //         //收到充值通知
            //         this.play();
            //     }
            // },
            initWebSocket(userId) {
                this.$connect(global.ws + '/connectWebSocket/' + userId)
            },


        }
    }
</script>

<style lang="scss" scoped>
    .asideClass {
        text-align: left;
        width: 210px;
        height: 1000px;
        background-color: #eeeeee;
    }

    .menuClass {
        background-color: #DCDFE6;
    }

    .titleClass2 {
        display: flex;
        justify-content: center;
    }

    .buttonClass {
        text-align: center;
        padding-top: 30px;
    }

    .el-icon-caret-bottom {
        color: white;
    }

    .el-header {
        color: #F7F7F7;
        line-height: 80px;
        text-align: right;
        font-size: 12px;
        background-color: #409EFF;
    }

    .el-icon-user-solid {
        font-size: 15px;
    }

    .spanBox {
        font-size: 15px;
        font-weight: bold;
        color: white;
    }

    .menuStyle {
        background-color: #DCDFE6;
    }

    .fontStyle {
        font-weight: bold;
        color: #409EFF;
        font-size: medium;
    }

    a {
        text-decoration: none;
    }

    .mainStyle {
        display: inline-block;
        padding-left: 20px;
        color: white;
        font-size: 25px;
        font-weight: bold;
    }

    .titleClass {
        display: flex;
        justify-content: space-between;
        height: 60px;
        background-color: #409EFF;
    }

    .icon-zhanghu1 {
        color: #df5000;
        font-size: 20px;
    }

    .icon-fenlei {
        color: #df5000;
        font-size: 20px;
    }

    .icon-xiangmu_xiangmuguanli {
        color: #df5000;
        font-size: 20px;
    }

    .icon-zhifu {
        color: #df5000;
        font-size: 20px;
    }

    .icon-jiangli {
        color: #df5000;
        font-size: 20px;
    }

    .icon-renzhengyonghu {
        color: #df5000;
        font-size: 20px;
    }

    .icon-shouye {
        color: #df5000;
        font-size: 20px;
    }

    .icon-examine {
        color: #df5000;
        font-size: 20px;
    }

    .icon-shuzhuangtu {
        color: #df5000;
        font-size: 20px;
    }

    .icon-chongzhi {
        color: #df5000;
        font-size: 20px;
    }

    .icon-xitongshezhi {
        color: #df5000;
        font-size: 20px;
    }

    .icon-huiyuanka {
        color: #df5000;
        font-size: 20px;
    }

    .icon-duihuanma {
        color: #df5000;
        font-size: 20px;
    }
</style>
